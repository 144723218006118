body {
  margin:0px;
  font-family: 'Play', sans-serif;
  font-size: 3.4vw;
  background: beige;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: inherit;
}
h2 {
  margin: inherit;
}
@media(hover: hover) and (pointer: fine) {
  .navButtons:hover {
    color: darkgreen;
    box-shadow: .25rem .25rem darkgray;

    border-radius: 5px;
    background-color: beige;
    text-shadow: .25rem .25rem darkgray;
    position: relative;
    top: -.25rem;
    left: -.25rem;
    transition: transform .25s;
  }
}
.navButtons {
  padding-inline: 5px;
}
.bigger {
  font-size: larger;
  font-weight: bold;
}
#TOP_BAR {
  font-weight: bolder;
  background-color:darkgreen;
  color: beige;
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0px;
  overflow: hidden;
  z-index: 3;
}
#BOTTOM_BAR {
  background-color: darkgreen;
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content:space-around;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 0px;
  z-index: 3;
  position: fixed;
  bottom: 0px;
  overflow: hidden;
}
#MIDDLE_BAR {
  display: block;
  text-align: left;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  margin-bottom: 0px;
  max-width: 1000px;
  padding-left: 10px;
  padding-right: 10px;
}

/*HOME PAGE */
#intro {
  font-size: 4rem;
  text-align: left;
  align-self: flex-start;
}
#intro2 {
  font-size: 3rem;
  width: 100%;
}
#intro3 {
  font-size: 2rem;
}
#mystory {
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

#bottomText {
  font-size: 4vw;
  text-align: left;
  white-space:normal;
  height: auto;
  margin: 1vh;
}
#container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
#traitIMG {
  max-width: 100%;
  max-height: 40%;
  margin-left: auto;
  margin-right: auto;
}
#circles {
  position: absolute;
  bottom: 5%;
  display:flex;
  align-items: center;
  justify-content: center;
}
.solidCircle {
  border-radius:100%;
  width: 40px;
  height: 40px;
  background-color: darkgreen;
  margin: 10px;
}
.whiteCircle {
  border-radius:100%;
  width: 40px;
  height: 40px;
  background-color: beige;
  margin: 10px;
}
.whiteCircle:hover {
  cursor: pointer;
}
.rightArrow {
  position: absolute;
  right: 3%;
  z-index: 2;
}
.leftArrow {
  position: absolute;
  left: 3%;
  z-index: 2;
}
.traits {
  font-family: 'Play', sans-serif;
  position: absolute;
  color: beige;
  text-align: center;
  margin: auto;
  padding-bottom: 5%;
  animation: mymove 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  text-shadow: -.3vw -.3vw 0 darkgreen,
		 .3vw -.3vw 0 darkgreen,
		-.3vw .3vw 0 darkgreen,
    .3vw .3vw 0 darkgreen;
  z-index: 2;
}
@keyframes mymove {
  from {
    font-size: 1vw;
  } to {
    font-size: 8vw;
  }
}

/* PROJECTS PAGE */
#newProjectContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
}
.projectIcon {
  height: inherit;
  border: 5px solid darkgreen;
  width: 65%;
}
.iconlink {
  margin-top: 20px;
  font-family: 'Play', sans-serif;
  font-size: 20px;
  color: darkgreen;
}
.ProjectzContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: darkgreen;
  height: fit-content;
  width: fit-content;
  font-family:'Fira Sans', sans-serif;
}
.photo_or_vid {
  max-height: 700px;
  max-width: 100%;
  box-shadow: 0 0 1em .02em black;
}
.mediaDiv{
  font-style: italic;
  font-size: smaller;
  padding: 2vw;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}
figure {
  width: fit-content;
}
figcaption{
  text-align: right;
}
.projectGIT {
  width: 13vw;
  margin-left: 2%;
  padding: 1%;
  background-color: black;
}
.gitz {
  display: flex;
  align-items: center;
}
#projectOUTERDIV {
  height: fit-content;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
#home {
  color: darkgreen;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-block-start: 40px;
  padding-block-end: 40px;
  text-align: left;
}
.ProjectTitle {
  font-family: 'Play', sans-serif;
  font-size: 50px;
  text-align: center;
}
.projectIconsHolder {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.buildStep {
  padding-top: 3px;
}
.iconBoxed {
  border: 2px solid darkgreen;
  margin: -1px;
}
/* TESTIMONIALS PAGE */
blockquote {
  margin: 0;
  font-family:'Fira Sans', sans-serif;
  font-size: 20px;
}
blockquote p {
  padding: 15px;
  background: darkgreen;
  color: beige;
  border-radius: 15px;
}
blockquote p::before {
  content: '\201C';
}
blockquote p::after {
  content: '\201D';
}
.tesimonialPerson {
  font-family: 'Play', sans-serif;
  font-size: 50px;
  color: darkgreen;
}
.exitSign {
  top:12vh;
  right: 5vw;
  position:fixed;
  cursor: pointer;
}
#modal {
  height: 81vh;
  width: 95vw;
  overflow: scroll;
  justify-content: center;
  align-items: center;
  border: 1vh solid darkgreen;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.olyaIMG {
  margin: 5px;
  width: 90vw;
}
.olyaSpan {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}
#testim {
  padding: 3%;
  height: fit-content;
  text-align: left;
}
figure {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}
/* SKILLS PAGE */

thead {
  text-align: center;
}
.attributeDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
table {
  font-size: 1rem;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 8px solid purple;
  height: inherit;
}
#tableDiv {
  padding-top: 40px;
  padding-bottom: 40px;
}
thead th:nth-child(1) {
  width: 32%;
}
thead th:nth-child(2) {
  width: 22%;
}
thead th:nth-child(3) {
  width: 28%;
}
thead th:nth-child(4) {
  width: 19%;
}
th,
td {
  padding: 3px;
  inline-size: min-content;
}
tbody td {
  text-align: center;
}
tbody>tr>:nth-child(1) {
  text-align: left;
}
tbody tr:nth-child(odd) {
  background-color:lightsteelblue;
}
tbody tr:nth-child(even) {
  background-color:gainsboro;
}
.imgwithinCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2%;
  gap: 4%;
}
.sorter {
  position: relative;
  margin-left: 5px;
  border: 2px solid white;
  padding: 2px;
  border-radius: 20%;
  cursor: pointer;
}
thead {
  height: 60px;
  color: white;
  background-color: purple;
}
#timeline {
  height: 400px;
  width: 200px;
}
#centerBar {
  position: absolute;
  top: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 20px;
  color: black;
}

/* BEGIN TIMELINE */
.TimeLineContainer{
  display: flex;
  height: 500px;
  padding-right: 50%;
  padding-bottom: 2%;
}
#centerline {
  position: absolute;
  top: inherit;
  height: inherit;
  width: 15px;
  background: linear-gradient(to bottom, beige, transparent 100%);
  background-color: black;
  border: none;
  margin-left: auto;
  margin-right: auto;
}
#years {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: inherit;
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  text-align: center;
  align-items: flex-end;
}
.year {
  width: 50px;
  background-color: darkgreen;
  height: 25px;
  color: beige;
  border-radius: 20%;
  font-size: 15px;
}
#BaxterLines {
  position: relative;
  height: 60%;
  top: 0px;
  width: 25px;
  border-bottom: 10px solid orange;
  border-right: 10px solid orange;
}
#BaxterDesc {
  height: fit-content;
  margin-left: 100%;
  width: fit-content;
  background-color: orange;
  padding: 10px;
  padding-left: 30px;
}
#BaxterDescBox {
  padding-top: 100px;
}
#HackReactorLines {
  position: relative;
  height: 12.5%;
  top: 64%;
  width: 25px;
  left: 35px;
  border-top: 10px solid yellowgreen;
  border-bottom: 10px solid yellowgreen;
  border-right: 10px solid yellowgreen;
}
#HRDesc {
  height: fit-content;
  width: fit-content;
  margin-left: 100%;
  padding: 8px;
  padding-left: 30px;
  background-color:yellowgreen;
  color: black;
}
#HRrDescBox {
  position: relative;
  bottom: 25px
}
#projectLines {
  position: relative;
  height: 26%;
  top: 72%;
  width: 22px;
  left: -47px;
  border-top: 10px solid  gold;
  border-right: 10px solid gold;
}
#projectLinesDesc {
  height: fit-content;
  width: fit-content;
  margin-left: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  padding-left: 30px;
  background-color:gold;
  color: black;
}
#projectLinesDescBox {
  padding-top: 22px;
}
#timelineout{
  display: contents;
}
.descBox {
  text-align: left;
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  color: black;
  font-size: 15px;
}
#fadedOrange {
  top: inherit;
  position: absolute;
  background-color: orange;
  background: linear-gradient(to bottom, beige, transparent 100%);
  width: 10px;
  height: 112px;
  left:100%;
  z-index: 1;
}
/* END TIMELINE */
#copyright {
  font-family: 'Play', sans-serif;
  padding-bottom: 40px;
  color: darkgreen;
  font-size: 1rem;
  text-align: center;
}